import React, { useEffect } from "react"
import { NewPlan, FreePlan, PurchasedPlan } from "../payment-plan"
import Text from "@/client/components/atoms/text"
import { Skeleton } from "../../atoms/ui/skeleton"
import { UserBO } from "@/server/features/user/bo"
import { UserCountryType } from "@/client/lib/interface"
import {
	TransactionProp,
	usePayment,
	useTransaction,
} from "@/client/store/local/payments"
import { useParams, useRouter, useSearchParams } from "next/navigation"

const PlanView = ({
	account,
	isLoading,
	countryCode,
}: {
	account: UserBO | undefined
	isLoading: boolean
	countryCode: UserCountryType
}) => {
	const { checkout, redirectToCustomerPortal } = usePayment()
	const router = useRouter()
	// @ts-expect-error transaction type is unknown
	const transaction: TransactionProp = useTransaction()

	const current_plans =
		account?.current_plans && account?.current_plans[countryCode]
	const upgrade_plans =
		account?.upgrade_plans && account?.upgrade_plans[countryCode]

	useEffect(() => transaction.clear(), [])

	const onPurchase = async (plan_code: string) => {
		await checkout({ plan_code, country_code: countryCode })
		// For paddle
		// const pricing_id = await checkout({ plan_code, country_code: countryCode })
		// if (pricing_id) {
		// 	transaction.setPlan(pricing_id as string, account?.email)
		// 	router.push(`/pricing?countryCode=${countryCode}`)
		// } else {
		// 	console.log("no result")
		// }
	}

	const onViewUserPlans = async () => {
		const portalUrl = await redirectToCustomerPortal({
			country_code: countryCode,
		})
		if (portalUrl) {
			router.push(portalUrl as string)
		}
		// For paddle
		// redirectToCustomerPortal({ country_code: countryCode }).then(data => {
		// 	if (data) {
		// 		router.push(data as string)
		// 	}
		// })
	}

	if (current_plans) {
		// on-going purchased plan
		let buttonText = undefined
		if (
			current_plans.plan_mode == "ONE_TIME" &&
			upgrade_plans &&
			upgrade_plans.length >= 1 &&
			upgrade_plans[1]
		) {
			buttonText = "Switch to Annual Plan"
		}

		return (
			<PurchasedPlan
				countryCode={countryCode}
				plan={current_plans}
				buttonText={buttonText}
				onViewPlanDetails={onViewUserPlans}
			/>
		)
	}

	if (upgrade_plans) {
		return (
			<>
				<FreePlan countryCode={countryCode} />
				<NewPlan
					countryCode={countryCode}
					plans={[...upgrade_plans].reverse()}
					onPurchase={onPurchase}
					loading={isLoading}
				/>
			</>
		)
	}

	return (
		<>
			<FreePlan countryCode={countryCode as UserCountryType} />
			<NewPlan
				countryCode={countryCode as UserCountryType}
				plans={[null, null]}
				onPurchase={onPurchase}
				loading={isLoading}
			/>
		</>
	)
}

const PageLayout = ({
	account,
	isLoading,
}: {
	account: UserBO | undefined
	isLoading: boolean
}) => {
	const { loading, error } = usePayment()
	const params = useParams()
	const searchParams = useSearchParams()
	let countryCode = (
		params?.["countryCode"] as string
	)?.toUpperCase() as UserCountryType
	if (!countryCode) {
		countryCode = searchParams
			.get("countryCode")
			?.toUpperCase() as UserCountryType
	}

	return (
		<>
			<div className="flex justify-between items-center border-b border-neutral-200 lg:px-12 lg:py-5 px-6 py-3">
				<div>
					<Text variant="text-semibold" className="hidden lg:flex text-sm">
						My Plan
					</Text>
				</div>
				<div className="font-medium text-sm">
					<span className="text-zinc-400">Market: </span>
					<span className="text-neutral-400">{countryCode}</span>
				</div>
			</div>
			{isLoading ? (
				<Skeleton className="mt-2 w-[85px] h-[12px]" />
			) : (
				<div className="flex flex-wrap overflow-y-auto m-4 lg:m-10">
					<PlanView
						account={account}
						isLoading={loading}
						countryCode={countryCode}
					/>
					{error.status ? (
						<p className="text-sm font-medium text-destructive">
							{error.message}
						</p>
					) : null}
				</div>
			)}
		</>
	)
}

export default PageLayout
