import { cn } from "@/client/lib/utils"
import React, { PropsWithChildren } from "react"

const VARIANT_CLASS = {
	Heading: "font-semibold text-neutral-500 leading-6 text-head text-lg",
	Subheading: "font-medium text-neutral-300 text-sm leading-6 text-subhead",
	Paragraph: "text-neutral-300 text-sm leading-relaxed font-normal",
	Primary: "font-medium text-neutral-400 text-sm",
	"text-thin": "text-zinc-400 flex items-center gap-x-1",
	"text-light": "text-neutral-300 flex items-center gap-x-3",
	"text-semibold":
		"text-neutral-400 font-semibold leading-8 flex items-center gap-x-2",
}

type PROPS_TYPE = {
	variant: keyof typeof VARIANT_CLASS
	className?: string
}

const BASE_CLASS = ""

export default function Text({
	variant,
	children,
	className,
	...props
}: PropsWithChildren<PROPS_TYPE>) {
	return (
		<div
			className={cn(BASE_CLASS, VARIANT_CLASS[variant], className)}
			{...props}>
			{children}
		</div>
	)
}
