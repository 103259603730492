import React from "react"
import { PricingCardTemplate }from "."
import { PRICING_CONTENT } from "@/client/constants"
import { UserCountryType } from "@/client/lib/interface"
import { PlanBO } from "@/server/features/user/bo"

const NewPlan = ({ countryCode, plans, onPurchase, loading }: { countryCode: UserCountryType, plans: (PlanBO | null)[], onPurchase: (plan_name: string) => void, loading?: boolean }) => {
	const planContent = PRICING_CONTENT[countryCode]["PRO_UNPAID"]

	return (
		<PricingCardTemplate
			buttonText={planContent.buttonText}
			title={planContent.title}
			planTitle={null}
			description={planContent.description}
			onClick={onPurchase}
			plans={plans}
			countryCode={countryCode}
			loading={loading}
		/>
	)
}

export default NewPlan
