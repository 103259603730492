import React, { useState } from "react"
import {
	Card,
	CardContent,
	CardFooter,
	CardHeader,
} from "@/client/components/atoms/ui/card"
import Text from "@/client/components/atoms/text"
import { CURRENCY_SYMBOL, CURRENCY_DISPLAY_TEXT } from "@/client/constants"
import {
	UserCountryType,
} from "@/client/lib/interface"
import { Check } from "lucide-react"
import { AIIcon } from "@/client/components/assets"
import {
    Tabs,
    TabsContent,
} from "@/client/components/atoms/ui/tabs"
import TabGroup from "@/client/components/atoms/tab-group"
import { PlanBO } from '@/server/features/user/bo'
import { cn } from '@/client/lib/utils'

type PricingCardTemplateProp = {
	planType?: "FREE" | "PRO_UNPAID" | "PRO_PAID"
	countryCode: UserCountryType
	buttonText: string
	isDisabled?: boolean
	onClick: (plan_name: string) => void
	title: string
	description: string[]
	plans: (PlanBO | null)[]
	planTitle?: React.ReactNode
	planState?: React.ReactNode
	loading?: boolean
}

const PricingCardTemplateHeader = ({
	plans,
	currency,
	currencyInText,
	planTitle,
	planState,
	setSelectedTerm
}: {
	plans: PlanBO[]
	currency: string
	currencyInText: string
	planTitle?: React.ReactNode
	planState?: React.ReactNode
	setSelectedTerm: React.Dispatch<React.SetStateAction<string | undefined>>
}) => (
	<CardHeader className="lg:px-12 lg:py-5 px-6 py-3">
		<Tabs
			defaultValue={plans[0].plan_period.toLowerCase()}
			className="flex flex-row justify-between"
			onValueChange={setSelectedTerm}>
			<>
				{plans?.map((plan: PlanBO | PlanBO, index) => (
					<PricingCardTemplatePricing
						key={`pricing-details-${index}`}
						planTitle={
							planTitle || (
								<Text variant="text-semibold">
									<AIIcon /> Pro
								</Text>
							)
						}
						current_plan_price={plan.current_plan_price}
						discounted_plan_price={plan.plan_price}
						term={plan.plan_period.toLowerCase()}
						currency={currency}
						currencyInText={currencyInText}
					/>
				))}
			</>
			{plans.length > 1 ? (
				<TabGroup values={plans.map(plan => plan.plan_period.toLowerCase())} tabGroupKey="plans" />
			) : planState ? (
				planState
			) : null}
		</Tabs>
	</CardHeader>
)

const PricingCardTemplatePricing = ({
	planTitle,
	current_plan_price,
	discounted_plan_price,
	term,
	currency,
	currencyInText
}: {
	planTitle: React.ReactNode
	current_plan_price: number
	discounted_plan_price: number
	term: string
	currency: string
	currencyInText: string
}) => (
	<TabsContent value={term} className="m-0">
		<Text variant="text-semibold">{planTitle}</Text>
		<Text variant="text-thin" className="text-sm flex self-end items-start flex-col mt-2">
			<div className="relative">
				<span className="text-s whitespace-nowrap">
					{currency}{discounted_plan_price} {currencyInText} <span className='text-xs'>/{term.toLowerCase()}{" "}</span>
				</span>
				{
					current_plan_price == discounted_plan_price ? null : 
						<span className="absolute -top-2 h-5 w-5">
							<svg className="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
								<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
							</svg>
						</span>
				}
			</div>
			{
				current_plan_price == discounted_plan_price ? null : (
					<div className="relative">
						<span className="text-s whitespace-nowrap"><s>
							{currency}{current_plan_price} {currencyInText} <span className='text-xs'>/{term.toLowerCase()}</span></s>
						</span>
					</div>
				)
			}
		</Text>
	</TabsContent>
)

const PricingCardTemplateAction = ({
	buttonText = "",
	isDisabled = false,
	onClick,
	children = null,
	asChild = false,
	loading = false
}: {
	buttonText: string
	isDisabled?: boolean
	onClick: () => void
	asChild?: boolean
	children?: React.ReactElement | null
	loading?: boolean
}) => (
	<CardContent className="space-y-2 lg:px-12 lg:py-5 px-6 py-3">
		{asChild ? (
			children
		) : buttonText ? (
			<button
					className={cn("w-full bg-primary text-white disabled:bg-zinc-200 disabled:text-zinc-400 px-10 py-5 rounded-lg font-semibold leading-6 text-xs whitespace-nowrap",
					(isDisabled ? "cursor-not-allowed" : (loading ? "animate-pulse cursor-wait" : "cursor-pointer"))
				)}
				disabled={isDisabled}
				onClick={onClick}>
				{loading ? "Loading..." : buttonText}
			</button>
		) : null}
	</CardContent>
)

const PricingCardTemplateDescription = ({
	title = "",
	description = [],
	isDisabled = false,
}: {
	title: string
	description: string[]
	isDisabled?: boolean
}) => (
	<CardFooter className="flex-col items-start gap-y-5 text-sm lg:px-12 lg:py-5 px-6 py-3">
		<Text variant="text-semibold">{title}</Text>
		<div className="flex flex-col gap-y-2.5 leading-6">
			{description.map((text, index) => (
				<Text variant="text-light" key={`desc-${index}`}>
					<Check
						className={`h-3 w-3 ${!isDisabled ? "text-primary" : ""}`}
						strokeWidth={4}
					/>
					{text}
				</Text>
			))}
		</div>
	</CardFooter>
)

PricingCardTemplateHeader.displayName = "PricingCardTemplateHeader"
PricingCardTemplatePricing.displayName = "PricingCardTemplatePricing"
PricingCardTemplateAction.displayName = "PricingCardTemplateAction"
PricingCardTemplateDescription.displayName = "PricingCardTemplateDescription"

const PricingCardTemplate = ({
	buttonText = "",
	isDisabled = false,
	onClick,
	title,
	description,
	plans,
	countryCode,
	planTitle,
	planState,
	loading = false
}: PricingCardTemplateProp) => {
	const [selectedTerm, setSelectedTerm] = useState<undefined | string>()

	const onSelect = () => {
		let plan_name
		if (selectedTerm) {
			plan_name = plans.filter(plan => plan?.plan_period == selectedTerm?.toUpperCase())[0]?.plan_code
		} else {
			plan_name = plans[0]?.plan_code
		}
		if (plan_name)
			onClick(plan_name)
	}

	const purchasable_plans = plans.filter(plan => plan != null) as PlanBO[]

	return (
		<div className="w-full max-w-[480px] border-neutral-200 border rounded-md lg:first:rounded-r-none lg:last:rounded-l-none lg:last:border-r lg:border-r-0 first:rounded-b-none last:rounded-t-none last:border-b border-b-0">
			<Card className="py-5 border-none rounded shadow-none">
				<PricingCardTemplateHeader
					plans={purchasable_plans}
					currencyInText={CURRENCY_DISPLAY_TEXT[countryCode]}
					currency={CURRENCY_SYMBOL[countryCode]}
					planTitle={planTitle}
					planState={planState}
					setSelectedTerm={setSelectedTerm}
				/>
				<PricingCardTemplateAction
					buttonText={buttonText}
					isDisabled={isDisabled}
					onClick={onSelect}
					loading={loading}
				/>
				<PricingCardTemplateDescription
					title={title}
					description={description}
					isDisabled={isDisabled}
				/>
			</Card>
		</div>
	)
}


export default PricingCardTemplate
